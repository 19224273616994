import React from 'react'
import styled from 'styled-components'

// 💅
const AddCreditCard = styled.div`
  // 📰
  grid-area: sg_credit_card;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Open Sans";
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-columns: 0.6fr 0.4fr;
  grid-template-areas:
  "g_nick_name g_nick_name"
  "g_full_name g_full_name"
  "g_address g_zipcode"
  "g_card_number g_card_number"
  "g_card_wrapper g_card_wrapper";

  .InvoiceTotal {
    grid-area: g_total; // 🗺
    justify-self: start;
    align-self: center;
    color: #666;
    font-weight: 600;
  }
  .InvoiceTotal__Amount {
    grid-area: g_amount; // 🗺
    justify-self: end;
    align-self: center;
    padding-right: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #2b363b;
  }
  .form-group.AccountNickName {
    grid-area: g_nick_name; // 🗺
  }
  .form-group.FullName {
    grid-area: g_full_name; // 🗺
  }
  .form-group.Address {
    grid-area: g_address; // 🗺
  }
  .form-group.ZipCode {
    grid-area: g_zipcode; // 🗺
  }
  .form-group.CardNumber {
    grid-area: g_card_number; // 🗺
  }


`;

const CardWrapper = styled.div`
  grid-area: g_card_wrapper;
  .wrap {
    display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  grid-template-areas:
  "g_month g_year g_cvv"
  }
  .form-group.Month {
    grid-area: g_month; // 🗺
  }
  .form-group.Year {
    grid-area: g_year; // 🗺
  }
  .form-group.CVV {
    grid-area: g_cvv; // 🗺
  }

`;
// ⚛️
const InvoicePageComponent = () => {
  return (
    <AddCreditCard>


      <div className="form-group AccountNickName">
        <label
          className="form-label label-lg"
        >
          Account Nickname
        </label>
        <input
          className="form-input input-lg"
          type="text"
          placeholder=""
        />
      </div>

      <div className="form-group FullName">
        <label
          className="form-label label-lg"
        >
          Full Name
        </label>
        <input
          className="form-input input-lg"
          type="text"
          placeholder=""
        />
      </div>




      <div className="form-group Address">
        <label
          className="form-label label-lg"
        >
          Billing Street Address
        </label>
        <input
          className="form-input input-lg"
          type="text"
          placeholder=""
        />
      </div>

      <div className="form-group ZipCode">
        <label
          className="form-label label-lg"
        >
          Billing Zip Code
        </label>
        <input
          className="form-input input-lg"
          type="text"
          placeholder=""
        />
      </div>

      <div className="form-group CardNumber">
        <label
          className="form-label label-lg"
        >
          Credit Card Number
        </label>
        <input
          className="form-input input-lg"
          type="text"
          placeholder=""
        />
      </div>




<CardWrapper>
<div className="wrap">
      <div className="form-group Month">
        <label
          className="form-label label-lg"
        >
          MM
        </label>
        <input
          className="form-input input-lg"
          type="text"
          placeholder=""
        />
      </div>
      <div className="form-group Year">
        <label
          className="form-label label-lg"
        >
          YY
        </label>
        <input
          className="form-input input-lg"
          type="text"
          placeholder=""
        />
      </div>
      <div className="form-group CVV">
        <label
          className="form-label label-lg"
        >
          CVV
        </label>
        <input
          className="form-input input-lg"
          type="text"
          placeholder=""
        />
      </div>
      </div>
</CardWrapper>

    </AddCreditCard>
  )
}

export default InvoicePageComponent;