import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import ButtonDocs from './AccordionButtons/ButtonDocs.js';
import ButtonLoad from './AccordionButtons/ButtonLoad.js';

import ModalInvoice from '../FunctionalComponents/Modal/ModalInvoice.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faIgloo, faCopy, faChevronCircleDown, faBookmark, faTruck } from '@fortawesome/free-solid-svg-icons'

library.add(faIgloo, faCopy, faChevronCircleDown, faBookmark, faTruck)



const AccordionComponentInvoiceStaticChecked = styled.div`
.es-c-shipment-accordion {
  .es-cg-shipment-accordion {
    // 💅
 
    // 🎛
    font-family: "Open Sans";
    // 🎨
    border-radius: 3px;
    background-color: #f7f7f7;
    margin-bottom: 3px;
    padding-top: 4px;
    padding-bottom: 4px;
    // 🖋
    color: #141b1f;
    font-size: 12px;
    font-weight: 400;
    // 📰
    display: grid;
    grid-template-rows: 55px;
    grid-template-columns: 0.5fr 1fr 0.5fr 0.5fr 1.2fr 1.2fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
    "sg_input sg_invoice sg_po_number sg_order_number sg_origin sg_destination sg_aging sg_amount_due sg_bol_btn sg_copy_btn";

    span {
      font-size: 12px;
      color: #666;
      font-weight: 400;
    }
    .sg-input {
      grid-area: sg_input; // 🗺
      margin-left: 20px;
      align-self: center;
      margin-top: 20px;
    }
    .sg-invoice {
      grid-area: sg_invoice; // 🗺
      align-self: center;
      color: #000;
    }
    .sg-po-number {
      grid-area: sg_po_number; // 🗺
      align-self: center;
    }
    .sg-order-number {
      grid-area: sg_order_number; // 🗺
      align-self: center;
    }
    .sg-origin {
      grid-area: sg_origin; // 🗺
      align-self: center;
      padding-left: 15px;
    }
    .sg-destination {
      grid-area: sg_destination; // 🗺
      align-self: center;
    }
    .sg-aging {
      grid-area: sg_aging; // 🗺
      align-self: center;
    }
    .sg-amount-due {
      grid-area: sg_amount_due; // 🗺
      align-self: center;
    }
    .sg-bol-btn {
      grid-area: sg_bol_btn; // 🗺
      align-self: center;
      justify-self: end;
    }
    .sg-copy-btn {
      grid-area: sg_copy_btn; // 🗺
      align-self: center;
      justify-self: end;
      padding-right: 20px;
    }

  }
}

`;

const EchoShipAccordionComponent = ({children}) => {
  return (
    <AccordionComponentInvoiceStaticChecked>
      <div className="es-c-shipment-accordion">
        <div className="es-cg-shipment-accordion">

        <Link to="/Invoices/OpenInvoice/">
          <input className="sg-input" type="checkbox" checked></input>
        </Link>
          <div className="sg-invoice">34915423-1</div>

          <div className="sg-po-number">N/A</div>

          <div className="sg-order-number">N/A</div>

          <div className="sg-origin">
            Brampton, ON<br />
            <span>L6T 5S8</span>
          </div>

          <div className="sg-destination">
            Elk Grove Village, IL<br />
            <span>60007</span>
          </div>
          
          <div className="sg-aging">
            2 days
          <br />
            <span>Due: Apr 19</span>
          </div>

          <div className="sg-amount-due">
          $960.92
          </div>

          <div className="sg-bol-btn">
            <ButtonLoad></ButtonLoad>
          </div>

          <div className="sg-copy-btn">
            <ModalInvoice>
              {children}
            </ModalInvoice>
          </div>



          

        </div>
      </div>
    </AccordionComponentInvoiceStaticChecked>
  )
}

export default EchoShipAccordionComponent;